<template>
    <v-card>
        <v-tabs color="indigo" dark fixed-tabs>
            <v-tab>All</v-tab>

            <v-tab-item>
                <v-container fluid grid-list-lg>
                    <!-- <v-layout v-if="!hasUserPurchased">
                      <PaymentAlert />
                    </v-layout>-->
                    <v-layout row wrap>
                        <v-flex :key="data.id" md4 sm6 v-for="data in testsAvailable" xs12>
                            <ResultDetailsCard :testData="data"/>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
    // @ is an alias to /src
    import ResultDetailsCard from "@/components/ResultCard.vue";

    import {APIService} from "../APIService";

    export default {
        metaInfo: {
            title: 'Results',
            titleTemplate: '%s - Dental Pockets ||| Mastering Dentisty the Easy Way!',
            htmlAttrs: {
                lang: 'en',
                amp: true
            },
            meta: [
                {httpEquiv: 'Content-Type', content: 'text/html; charset=utf-8'},
                {
                    name: 'description',
                    content: "NEET, NBDE, Dental Exams, PGI, JIPMER, DHA, preparation of dental exams now easy. Image based questions are now made easy."
                }
            ]
        },
        name: "tests",
        components: {
            ResultDetailsCard,
        },
        data: () => ({
            data: {
                testName: "General Anatomy",
                subtitle: "200 Questions, 180 Minutes",
                price: "100"
            },

            hasUserPurchased: false,
            testsAvailable: []
        }),
        mounted: function () {
            this.$store.commit("setShowProgress", true);

            this.$nextTick(function () {
                // Code that will run only after the
                // entire view has been rendered
                this.getTestsFromServer();
            });
        },
        methods: {
            getTestsFromServer() {
                const app = this;

                let uid = window.localStorage.getItem("uid");

                APIService.getAllResults(uid).then(data => {
                    // console.log(data);
                    app.testsAvailable = data.data;

                    app.$store.commit("setShowProgress", false);

                    // console.log(app.testsAvailable);
                });
            }
        }
    };
</script>
