<template>
    <v-flex>
        <v-card>
            <v-img :src="testData.imageUrl" height="200px"/>

            <v-card-title primary-title>
                <div>
                    <div class="headline">{{testData.testName}}</div>
                    <span class="grey--text">{{testData.subtitle}}</span>
                    <p class="grey--text">₹ {{testData.price}}</p>
                </div>
            </v-card-title>

            <v-card-actions>
                <v-btn @click="attempt(testData.id)">Result</v-btn>
                <!-- <v-btn flat color="purple">Explore</v-btn> -->
                <v-spacer/>
                <v-btn @click="show = !show" icon>
                    <v-icon>{{ show ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                </v-btn>
            </v-card-actions>

            <v-slide-y-transition>
                <v-card-text v-show="show">{{testData.details}}</v-card-text>
            </v-slide-y-transition>
        </v-card>
    </v-flex>
</template>
<script>
    export default {
        props: {
            testData: {
                type: Object
            }
        },
        data: () => ({
            show: false
        }),
        methods: {
            attempt(id) {
                // console.log(id)
                this.$router.push({name: "solution", params: {id: id}});
            }
        }
    };
</script>